/* configuration */
import mergeDeepLeft from 'ramda/src/mergeDeepLeft';

const screens = require( '../../../tailwind.config' ).screens;

// TODO -> refactor Convert this file into a config import instead of binding to a namespace

(function ( upi ) {
    const
        pathsConfig = upi.namespace( 'paths' ),
        upiConfig = upi.namespace( 'conf' ),
        upiScreen = upi.namespace( 'screen' ),
        envConfig = upi.namespace( 'env' );

    const BASE_FONT_SIZE = parseInt( $( 'html' ).css( 'fontSize' ), 10 );

    upi.breakPoints = {
        screenSmallest: 0 * BASE_FONT_SIZE,
        screenSmall: parseFloat( screens.sm, 10 ) * BASE_FONT_SIZE,
        screenMedium: parseFloat( screens.md, 10 ) * BASE_FONT_SIZE,
        screenLarge: parseFloat( screens.lg, 10 ) * BASE_FONT_SIZE,
        screenBase: parseFloat( screens.xl, 10 ) * BASE_FONT_SIZE
    };

    upi.mq = (function () {
        const bp = upi.breakPoints, queries = {min: {}, max: {}};

        for ( let key in bp ) {
            if ( bp.hasOwnProperty( key ) ) {
                queries.min[key] = `screen and (min-width: ${bp[key]}px)`;
                queries.max[key] = `screen and (max-width: ${bp[key]}px)`;
            }
        }

        return queries;
    })();

    upi.paths = mergeDeepLeft( pathsConfig, {
        /*        ORIGIN: 'https://www.ultradent.com',
                ORIGIN_CDN: 'https://www.ultradent.com',
                SITE_URL: 'https://www.ultradent.com',
                IMAGES_ORIGIN: 'https://images.ultradent.com',
                IMAGES_ORIGIN_CDN: 'https://images.ultradent.com',
                STORE_ORIGIN: 'https://www.ultradent.com',
                STORE_URL: 'https://www.ultradent.com/checkout'*/
        FIELD_PREFIX: 'ctl00$ContentPlaceHolderBody$',
        IMAGE_RESIZE_SVC_HOST: 'images.ultradent.com',
        CART_URL: window.location.origin,
        SVC_ORIGIN: window.location.origin + '/api',
        ORDER_SVC: '/api/Order/',
        ZIPCODEINFO_SVC: '/api'
    } );

    upi.paths = {
        ...upi.paths,
        CART_URL: '/cart',
        LOGIN_URL: '/login'
    };

    upi.conf = mergeDeepLeft( upiConfig, {
        features: {},
        SCROLL_CONTEXT: Modernizr.mq( upi.mq.min['screenMedium'] ) ? '#content-pane-scroller' : window,
        BASE_FONT_SIZE,
        PHONE_TOLL_FREE: '1-888-230-1420'
    } );

    upi.selector = {
        SITE_MESSAGE_BAR: '.js-site-message-bar',
        SECONDARY_HEADER$: '.header-secondary',
        PAGE_CONTENT$: '#page-content',
        MOBILE_MENU_OPEN$: 'js-mobile-menu--open',
        HEADER$: '#header-site',
        DROP_MENU$: '.nav-drop-menu',
        PRIMARY_NAV$: '#nav-lvl-primary',
        MENU_TRIGGER$: '[data-menu-trigger]',
        MOBILE_MENU_TRIGGER$: '[data-action="site-menu-toggle--mobile"]'
    };

    upi.dialog = {
        GENERIC_SERVICE_ERROR: 'An error occurred while attempting to perform this action. Please try again later.'
    };

    upi.env = mergeDeepLeft( envConfig, {
        IS_IPAD: /ipad/gi.test( navigator.platform ),
        IS_RETINA: 'devicePixelRatio' in window && window.devicePixelRatio > 1
    } );

    // todo -> move to a util import
    upi.screen = mergeDeepLeft( upiScreen, {
        getBreakPointAlias: () => {
            /* Conditional CSS http://adactio.com/journal/5429/ */
            if ( window.getComputedStyle ) {
                return window
                    .getComputedStyle( document.body, ':after' )
                    .getPropertyValue( 'content' )
                    .replace( /\"/g, '' )
                    .trim();
            }
            // default to large if getComputedStyle not available
            return 'large';
        },

        getBreakPointValue: () => {
            let alias = upi.screen.getBreakPointAlias();
            // uppercase first letter
            alias = alias.charAt( 0 ).toUpperCase() + alias.slice( 1 );
            return upi.breakPoints['screen' + alias];
        }

    } );

    upi.prefixes = {
        transEndEventName: (function () {
            return {
                WebkitTransition: 'webkitTransitionEnd',
                MozTransition: 'transitionend',
                OTransition: 'oTransitionEnd',
                msTransition: 'MSTransitionEnd',
                transition: 'transitionend'
            }[Modernizr.prefixed( 'transition' )];
        })(),

        transStartEventName: (function () {
            return {
                WebkitTransition: 'webkitTransitionStart',
                MozTransition: 'transitionStart',
                OTransition: 'oTransitionStart',
                msTransition: 'MSTransitionStart',
                transition: 'transitionstart'
            }[Modernizr.prefixed( 'transition' )];
        })(),

        animEndEventName: (function () {
            return {
                WebkitAnimation: 'webkitAnimationEnd',
                MozAnimation: 'animationend',
                OAnimation: 'oAnimationEnd',
                msAnimation: 'MSAnimationEnd',
                animation: 'animationend'
            }[Modernizr.prefixed( 'animation' )];
        })(),

        animStartEventName: (function () {
            return {
                WebkitAnimation: 'webkitAnimationStart',
                MozAnimation: 'animationStart',
                OAnimation: 'oAnimationStart',
                msAnimation: 'MSAnimationStart',
                animation: 'animationstart'
            }[Modernizr.prefixed( 'animation' )];
        })()
    };

})( window.upi );
