import assign from 'object-assign';

export const FBQCustomEvent = {
    ADD_TO_CART: 'AddToCart',
    HOMEPAGE_VIEW: 'ViewContent',
    PRODUCT_VIEW: 'ProductView',
    FIND_DENTIST_VIEW: 'FindLocation',
    INIT_CHECKOUT: 'InitiateCheckout',
    ADD_PAYMENT: 'AddPaymentInfo',
    PURCHASE: 'Purchase',
    NEWSLETTER_REGISTRATION: 'CompleteRegistration'
};

const facebookPixelProto = {
    send( eventName, data = {} ) {
        if ( !window.fbq ) {
            window.fbq = function () {
            };
        }

        fbq( 'track', eventName, data );
        return this;
    },

    createProductData( item ) {
        return {
            name: `${item.name} ${item.type}`,
            brand: item.name,
            category: 'Whitening',
            id: item.sku,
            price: parseFloat( item.price ),
            quantity: item.quantity
        };
    }
};

export const facebookPixelLayer = assign( {}, facebookPixelProto, {
    itemsAdded( items = [] ) {
        if ( !Array.isArray( items ) ) {
            items = [items];
        }

        items.forEach( item => {
            const data = this.createProductData( item );
            this.send( FBQCustomEvent.ADD_TO_CART, data );
        } );
    },

    newsletterRegistration() {
        this.send( FBQCustomEvent.NEWSLETTER_REGISTRATION );
    },
    viewHomepage() {
        this.send( FBQCustomEvent.HOMEPAGE_VIEW );
    },
    viewCart() {
        this.send( FBQCustomEvent.INIT_CHECKOUT );
    },
    viewPayment() {
        this.send( FBQCustomEvent.ADD_PAYMENT );
    },
    viewFindDentist() {
        this.send( FBQCustomEvent.FIND_DENTIST_VIEW );
    },
    purchaseComplete( data ) {
        this.send( FBQCustomEvent.PURCHASE, data );
    },

    productView: function ( item ) {
        // const product = this.createFBQProductData( item );
        // const data = this.createGAEventData( {
        //     event: GACustomEvent.PRODUCT_VIEW,
        //     ecommerceMeasurement: GAMeasurementType.DETAIL,
        //     data: {products: [product]}
        // } );

        this.send( data );
    }
} );
