import assign from 'object-assign';

export const GACustomEvent = {
    IMPRESSION: 'impression',
    ADD_TO_CART: 'addToCart',
    REMOVE_FROM_CART: 'removeFromCart',
    PRODUCT_VIEW: 'productView',
    PRODUCT_CLICK: 'productClick'
};
export const GAMeasurementType = {
    IMPRESSIONS: 'impressions',
    CLICK: 'click', // A click on a product or product link for one or more products.
    DETAIL: 'detail', // A view of product details.
    ADD: 'add', // Adding one or more products to a shopping cart.
    REMOVE: 'remove', // Remove one or more products from a shopping cart.
    CHECKOUT: 'checkout', // Initiating the checkout process for one or more products.
    CHECKOUT_OPTION: 'checkout_option', // Sending the option value for a given checkout step.
    PURCHASE: 'purchase', // The sale of one or more products.
    REFUND: 'refund', // The refund of one or more products.
    PROMO_CLICK: 'promo_click' // A click on an internal promotion.
};

const googleAnalyticsProto = {
    send( data, callback ) {
        if ( !window.dataLayer ) {
            window.dataLayer = [];
        }

        if ( callback && typeof callback === 'function' ) {
            data.eventCallback = callback
        }

        window.dataLayer.push( data );
        return this;
    },
    createGAEventData( {event, ecommerceMeasurement, data} ) {
        return {
            event,
            ecommerce: {
                [ecommerceMeasurement]: data
            }
        };
    },

    createGAProductData( item ) {
        return {
            name: `${item.name} ${item.type}`,
            brand: item.name,
            category: 'Whitening',
            id: item.sku,
            price: item.price,
            quantity: item.quantity
        };
    }
};

export const googleAnalyticsLayer = assign( {}, googleAnalyticsProto, {
    itemsAdded( items = [], callback ) {
        if ( !Array.isArray( items ) ) {
            items = [items];
        }
        const products = items.map( this.createGAProductData );
        const data = this.createGAEventData( {
            event: GACustomEvent.ADD_TO_CART,
            ecommerceMeasurement: GAMeasurementType.ADD,
            data: {products}
        } );

        this.send( data, callback );
    },

    itemsRemoved( items = [], callback ) {
        if ( !Array.isArray( items ) ) {
            items = [items];
        }
        const products = items.map( this.createGAProductData );
        const data = this.createGAEventData( {
            event: GACustomEvent.REMOVE_FROM_CART,
            ecommerceMeasurement: GAMeasurementType.REMOVE,
            data: {products}
        } );

        this.send( data, callback );
    },

    productView: function ( item ) {
        const product = this.createGAProductData( item );
        const data = this.createGAEventData( {
            event: GACustomEvent.PRODUCT_VIEW,
            ecommerceMeasurement: GAMeasurementType.DETAIL,
            data: {products: [product]}
        } );

        this.send( data );
    }
} );
