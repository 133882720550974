import assign from 'object-assign'

export var Events = Object.create({
    bind() {
        if ( !this.el ) {
            this.el = jQuery( {} );
        }

        this.el.bind.apply( this.el, arguments );
    },
    unbind() {
        if ( !this.el ) {
            this.el = jQuery( {} );
        }

        this.el.unbind.apply( this.el, arguments );
    },
    one() {
        if ( !this.el ) {
            this.el = jQuery( {} );
        }

        this.el.one.apply( this.el, arguments );
    },
    trigger() {
        if ( !this.el ) {
            this.el = jQuery( {} );
        }

        this.el.trigger.apply( this.el, arguments );
    }
});
