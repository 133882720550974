// keyboard event keycode lookups
export const digitKeys = (function () {
    const keyMap = new Map();
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].forEach( i => keyMap.set( i.toString(), i ) );

    return keyMap;
})();

export const specialKeys = new Map();
specialKeys.set( 0, 'other' );
specialKeys.set( 8, 'delete' );
specialKeys.set( 13, 'enter' );

export const arrowKeys = new Map();
arrowKeys.set( 38, 'up' );
arrowKeys.set( 39, 'right' );
arrowKeys.set( 40, 'down' );
arrowKeys.set( 41, 'left' );

export const metaKeys = new Map();
metaKeys.set( 17, 'ctrl' );
metaKeys.set( 18, 'alt' );
metaKeys.set( 91, 'meta' );

