export function embedMediaToTarget( autoplay ) {
    if ( !this.href ) {
        throw Error( 'Media src "href" not specified' );
    }

    const $this = $( this ),
        media = getMediaEmbedFor( this.href, autoplay ),
        target = $this.data( 'embed-target' );

    // look for target in siblings
    if ( target ) {
        $this.addClass( 'has-video-embed' ).find( '.' + target ).replaceWith( media );
    }
    else {
        $this.replaceWith( media );
    }
}

export function getMediaEmbedFor( url, autoplay ) {
    const
        youtubeUrl = url.match( /\/\/(?:youtu.be|www.youtube.com)\/(?:embed\/|watch\?v=)([a-zA-Z0-9\-_]+)/ ),
        vimeoUrl = url.match( /\/\/(www\.)?vimeo\.com\/(clip\:)?(\d+).*$/ );

    if ( youtubeUrl ) {
        return (
            `<div class="video-wrapper">
                <iframe 
                    src="https://www.youtube.com/embed/${youtubeUrl[1]}?autoplay=${autoplay ? '1' : '0'}&rel=0&wmode=opaque" 
                    frameborder="0" 
                    allowfullscreen>
                </iframe>
             </div>`
        );
    }
    else if ( vimeoUrl ) {
        return (
            `<div class="video-wrapper">
                <iframe src="https://player.vimeo.com/video/${vimeoUrl[3]}" frameborder="0"></iframe>
            </div>`
        );
    }
}
