const Template = {
    create: (function () {
        var cache = {};
        var err = '';

        return function ( str, data ) {
            // console.log( 'str:', str, data );
            try {
                var func = cache[str];
                if ( !func ) {
                    var strFunc = 'var p=[],render=function(){p.push.apply(p,arguments);};with(obj){p.push(\'' +
                        str.replace( /[\t\r\n]/g, ' ' )
                            .replace( '<![CDATA[', '' )
                            .replace( ']]>', '' )
                            .replace( /'(?![^%]*#>)/g, '\\\'' )
                            .replace( /{{(.+?)}}/g, '\',$1,\'' )
                            .split( '{%' )
                            .join( '\');' )
                            .split( '%}' )
                            .join( 'p.push(\'' ) +
                        '\');}return p.join(\'\');';
                    // alert(strFunc);
                    func = new Function( 'obj', strFunc );
                    cache[str] = func;
                }
                return func.call( this, data );
            }
            catch ( e ) {
                err = e.message;
                if ( typeof console !== 'undefined' ) {
                    console.error( 'ERROR: upi.Template: ' + err );
                    return null;
                }
            }
        };
    })()
};

export default Template;