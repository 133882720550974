const Footer = {
    init ( el ) {
        $( el ).on( 'click', '.nav-group-footer > a', e => {
            if ( Modernizr.mq( upi.mq.max['screenMedium'] ) ) {
                const $target = $( e.currentTarget );
                if ( $target.siblings( 'ul' ).length ) {
                    e.preventDefault();

                    $target.parent().toggleClass( 'nav-group-footer--active' );
                }
            }
        } )
    }
};

export default Footer;
