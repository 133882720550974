$.fn.scrollToElement = function ( options ) {
    var dfd = new $.Deferred(),
        defaults = {
            effect_speed: 500,
            offset: 0 // default to 45 to account for fixed header
        },
        opt = $.extend( defaults, options || {} ),
        delay = parseInt( opt.delay, 10 ),
        body_elm = $( upi.conf.SCROLL_CONTEXT ),
        target = $( this );

    function doScroll () {
        $.when(
            body_elm.animate( {
                    scrollTop: target.offsetFromAncestor( '#page-content' ).top + opt.offset
                },
                opt.effect_speed,
                'easeInOutCirc'
            ).promise()
        ).done( function () {
            dfd.resolve();
        } );
    };

    if ( delay && !isNaN( delay ) ) {
        setTimeout( doScroll, delay )
    }
    else {
        doScroll();
    }

    return dfd.promise();
};

$.fn.scrollToTarget = function () {
    return $( this ).each( function () {
        var $target = $( '#' + this.href.split( '#' )[1] );
        // only attach to <a href="#target">
        if ( !this.href || !$target[0] ) {
            return;
        }

        $( this ).gotoByScroll( $target );
    } );
};

$.fn.gotoByScroll = function ( target, opt ) {
    var defaults = {
            offset: 0,
            effect_speed: 600,
            event_trigger: 'click'
        },
        opt = $.extend( defaults, opt ),
        target = $( target );

    if ( !target[0] ) {
        return;
    }

    return $( this ).each( function () {
        if ( $( this )[opt.event_trigger] ) {
            $( this )[opt.event_trigger]( function ( e ) {
                e.preventDefault();
                target.scrollToElement( opt );
            } );
        }
    } );
};
