export default {

    async init () {
        const Module = await Modernizr.mq( upi.mq.min['screenLarge'] )
            ? import( /*webpackChunkName: "z-desktopMenu"*/ './DesktopMenu' )
            : import( /*webpackChunkName: "z-mobileMenu"*/ './MobileMenu' );

        Module.then( Menu => {
            if ( Menu && Menu.default ) {
                new Menu.default();
            }
        } )
    }
}
