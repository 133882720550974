import ServiceProvider from '@services/serviceProvider';
import { data } from 'jquery';
import assign from 'object-assign';

const PRODUCTS_API = 'productsapi';

var provider = ServiceProvider.register(
    upi.paths.SVC_ORIGIN, {
        [PRODUCTS_API]: {method: 'get'}
    }
);

export default assign( {}, provider, {
    productsApi () {
        return this.request( PRODUCTS_API );
    }
} );