import InputMask from '@plugins/inputmask.extensions';
import {digitKeys, specialKeys} from '@util/keycodes';

import '@plugins/jquery.scrolling';

Object.extend( upi.namespace( 'forms' ), {
    // Dictionaries used for validations keys
    inputmask: new InputMask(),

    restrict_input_values: function ( e ) {
        // resource: http://javascript.info/tutorial/keyboard-events
        var keycode = (typeof e.which == 'number') ? e.which : e.keyCode,
            isMetaKey = e.ctrlKey || e.metaKey || specialKeys.has( keycode ),
            $target = $( e.target );

        switch ( true ) {
            // restrict to digits and cursor keys
            case $target.hasClass( 'digits' ) || $target.attr( 'type' ) == 'number':
                // if character typed is a digit (charCode lookup) or a special_key: tab, arrow, delete, enter
                validate(
                    digitKeys.has( String.fromCharCode( keycode ) ) || isMetaKey,
                    'This field only accepts numbers'
                )
                ;
                break;

            case $target.hasClass( 'alphanumeric' ):
                validate(
                    /[a-zA-Z0-9\-\_!@#\$%\^\&\*\(\)]/.test( String.fromCharCode( keycode ) ) || isMetaKey,
                    'This field only accepts alphanumeric characters' );
                break;
        }

        function validate ( validates, msg ) {
            'use strict';
            if ( !validates ) {
                e.preventDefault();
                $target.showHint( msg, {
                    hide_trigger: 'blur keydown'
                } );
            }
        }

        return true;
    },

    /**
     * Scroll to first error in form
     * @param  {HTMLFormElement}    form
     * @return {HTMLInputElement}   input - first form element in for that is has invalid input
     */
    gotoFirstError: function ( validator ) {
        var first_error = validator.errorList && validator.errorList[0] && validator.errorList[0].element,
            delayTime = 400;

        if ( first_error ) {
            $( first_error ).parent().scrollToElement( {delay: delayTime} );
            setTimeout( function () {
                first_error.focus();
            }, delayTime );
        }

        return first_error;
    }
} );

