// Avoid `console` errors in browsers that lack a console.
(function () {
    var method;
    var noop = function () {};
    var methods = [
        'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
        'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
        'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
        'timeStamp', 'trace', 'warn'
    ];
    var length = methods.length;
    var console = (window.console = window.console || {});

    while ( length-- ) {
        method = methods[length];

        // Only stub undefined methods.
        if ( !console[method] ) {
            console[method] = noop;
        }
    }
}());

// make omniture var available
window.s = window.s || {};
window.s.t = window.s.t || function () {};

// IE Fallback for array prototype slice
if ( navigator.appVersion.indexOf( 'MSIE 8' ) > 0 ) {
    var _slice = Array.prototype.slice;
    Array.prototype.slice = function () {
        if ( this instanceof Array ) {
            return _slice.apply( this, arguments );
        }
        else {
            var result = [];
            var start = (arguments.length >= 1) ? arguments[0] : 0;
            var end = (arguments.length >= 2) ? arguments[1] : this.length;
            for ( var i = start; i < end; i++ ) {
                result.push( this[i] );
            }
            return result;
        }
    };
}

// create a data store for previously calculated results
if ( !Function.prototype.memoized ) {
    Function.prototype.memoized = function ( key ) {
        this._value = this._value || {};
        return (this._value[key] !== undefined) ? this._value[key] : this._value[key] = this.apply( this, arguments );
    };
}
// returns a function whos calculated results will be memoized
if ( !Function.prototype.memoize ) {
    Function.prototype.memoize = function () {
        var fn = this;
        return function () {
            return fn.memoized.apply( fn, arguments );
        };
    };
}

// Pre-populate arguments for a given method and call in current context
if ( !Function.prototype.curry ) {
    Function.prototype.curry = function () {
        var fn = this, slice = Array.prototype.slice, args = slice.call( arguments );

        return function () {
            return fn.apply( this, args.concat( slice.call( arguments ) ) );
        };
    };
}

if ( !Function.prototype.wrap ) {
    Function.prototype.wrap = function ( wrapper ) {
        var __method__ = this;
        return function () {
            var args = Array.prototype.slice.call( arguments );
            return wrapper.apply( this, [__method__.bind( this )].concat( args ) );
        };
    };
}

/**
 * String Prototypes
 */
if ( !String.prototype.reverse ) {
    String.prototype.reverse = function () {
        return this.split( '' ).reverse().join( '' );
    };
}
if ( !String.prototype.truncate ) {
    String.prototype.truncate = function ( len ) {
        len = (len <= 0) ? 0 : parseInt( len ) || 100;
        if ( this.length > len ) {
            // find last word boundary starting at specified length and trim
            return this.substr( 0, len ).replace( /\s+\w+$/, '' );
        }
        return this;
    };
}

/**
 * Date Prototypes
 */
Date.prototype.getMonthName = function ( lang ) {
    lang = lang && (lang in Date.locale) ? lang : 'en';
    return Date.locale[lang].month_names[this.getMonth()];
};

Date.prototype.getMonthNameShort = function ( lang ) {
    lang = lang && (lang in Date.locale) ? lang : 'en';
    return Date.locale[lang].month_names_short[this.getMonth()];
};

Date.locale = {
    en: {
        month_names: ['January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'],
        month_names_short: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    }
};

/**
 * Number Prototypes
 */
if ( !Number.prototype.toCurrency ) {
    Number.prototype.toCurrency = function ( round_decimal ) {
        // format decimal or round to nearest integer
        var n = this.toFixed( round_decimal ? 0 : 2 );
        // convert currency to a string, add commas every 3 digits from left to right by reversing string
        return (n + '').reverse().replace( /(\d{3})(?=\d)/g, '$1,' ).reverse();
    };
}

/* Object extensions */

/**
 * ES5 support for legacy browser: Covers most upi use cases
 * Disregards support for second param, support for this is not available
 * for older EcamScript engines
 */
if ( !Object.create ) {
    Object.create = function ( o ) {
        if ( arguments.length > 1 ) {
            throw new Error( 'Object.create implementation only accepts the first parameter.' );
        }

        function F () {
        }

        F.prototype = o;
        return new F();
    };
}

/**
 * Object.makeCopy:
 * Make deep copy of an object by manually copying properties
 */
if ( !Object.makeCopy ) {
    Object.makeCopy = function ( p, c ) {
        var c = c || {};
        for ( var i in p ) {
            if ( 'object' === typeof p[i] && p[i] !== null ) {
                c[i] = (p[i].constructor === Array) ? [] : {};
                Object.makeCopy( p[i], c[i] );
            }
            else {
                c[i] = p[i];
            }
        }
        return c;
    };
}

/**
 * Pure javascript Object.extend with deep copy
 */
if ( !Object.extend ) {
    Object.extend = function ( orig ) {
        if ( orig == null ) {
            return orig;
        }
        for ( var i = 1; i < arguments.length; i++ ) {
            var obj = arguments[i];
            if ( obj != null ) {
                for ( var prop in obj ) {
                    if ( obj.hasOwnProperty( prop ) ) {
                        var getter, setter;

                        if ( obj.__lookupGetter__ && obj.__lookupSetter__ ) {
                            getter = obj.__lookupGetter__( prop );
                            setter = obj.__lookupSetter__( prop );
                        }

                        if ( getter || setter ) {
                            if ( getter ) {
                                orig.__defineGetter__( prop, getter );
                            }
                            if ( setter ) {
                                orig.__defineSetter__( prop, setter );
                            }
                        }
                        else if ( $.isPlainObject( orig[prop] ) ) {
                            // deep copy for nested objects
                            Object.extend( orig[prop], obj[prop] );
                        }
                        else {
                            orig[prop] = obj[prop];
                        }
                    }
                }
            }
        }
        return orig;
    };
}

/**
 * Math.guid: Extend Math to create GUID for generating model ids
 */
if ( !Math.guid ) {
    Math.guid = function () {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace( /[xy]/g, function ( c ) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString( 16 );
        } ).toUpperCase();
    };
}
