const countryNameMap = {
    'au': 'Australia',
    'cn': 'China',
    'cz': 'Czech Republic',
    'dk': 'Denmark',
    'eu': 'Europe',
    'fi': 'Finland',
    'fr': 'France',
    'de': 'Germany',
    'it': 'Italy',
    'jp': 'Japan',
    'la': 'Latin America',
    'my': 'Malaysia',
    'nl': 'Netherlands',
    'no': 'Norway',
    'pl': 'Poland',
    'sg': 'Singapore',
    'za': 'South Africa',
    'es': 'Spain',
    'se': 'Sweden',
    'th': 'Thailand',
    'tr': 'Turkiye',
    'en-us': 'United States',
};

export default countryNameMap;
